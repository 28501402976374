@font-face {
  font-family: axiformaregular;
  src: url(./fonts/axiforma_regular-webfont.woff2) format('woff2'),
    url(./fonts/axiforma_regular-webfont.woff) format('woff');
}

@font-face {
  font-family: axiformabold;
  src: url(./fonts/axiforma_bold-webfont.woff2) format('woff2'),
    url(./fonts/axiforma_bold-webfont.woff) format('woff');
}

@font-face {
  font-family: axiformathin;
  src: url(./fonts/axiforma_thin-webfont.woff2) format('woff2'),
    url(./fonts/axiforma_thin-webfont.woff) format('woff');
}

@font-face {
  font-family: tiempossemi;
  src: url(./fonts/tiempos-fine-web-semibold.woff2) format('woff2'),
    url(./fonts/tiempos-fine-web-semibold.woff) format('woff');
}
